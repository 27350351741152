
export class LuigisBoxProvider {

    static sendProductDetail(product) {
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            'ecommerce': {
                'detail': {
                    'actionField': { 'list': '' },
                    'products': [{
                        'name': product.name,
                        'id': product.id,
                        'price': product.price,
                        'brand': '',
                        'category': product.category,
                        'variant': product.variant
                    }]
                }
            }
        });
    }


    static sendorder(order) {

   /*     dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': order.invoiceCode,
                        'affiliation': 'Online Store',
                        'revenue': order.partialPriceTable.cart.totalPriceExclVat,
                        'tax': order.priceTable.totalVat,
                        'shipping': order.partialPriceTable.shipping.totalPriceExclVat,
                        //'coupon': ''
                    },
                    'products': order.orderedProducts.map(cartedProduct => {
                        return {
                            'name': cartedProduct.product.name,
                            'id': cartedProduct.product.id,
                            'price': cartedProduct.priceTable.totalPriceInclVat,
                            'brand': '',
                            //'category': 'Apparel',
                            //'variant': 'Gray',
                            'quantity': cartedProduct.count
                        }
                    })
                }
            }
        });*/
    }
}
